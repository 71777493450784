<script setup lang="ts">
import ExclusiveProjectCard from '@/components/exclusive-project-card/ExclusiveProjectCard.vue';

const { fetchExclusiveProjects } = useProjects();
const { exclusiveProjects, isLoading } = await fetchExclusiveProjects();
</script>

<template>
  <div v-if="isLoading" class="ssc-square exclusive-projects__skeleton" />
  <div v-show="!isLoading" class="exclusive-projects">
    <ExclusiveProjectCard
      v-if="exclusiveProjects?.data.length"
      :projects="exclusiveProjects?.data || []" />
  </div>
</template>

<style lang="scss" src="./style.scss" />
